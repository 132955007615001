<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="gestaosesmt_cadastros_epc"
        newPath="/EPC/new"
        editPath="/EPC/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="EPC"
        tooltip="Registro de EPC"
        nomeTelaDoManual="epc-list"
        origemHistoricoAcao="/epc"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="epc.id" header="Cód." />
            <Column field="nome" :sortable="true" header="EPC" />
            <Column field="descricao" :sortable="true" header="Descrição" />
            <Column field="dt_create" :sortable="true" sortField="epc.dt_create" header="Criado" bodyStyle="min-width: 15rem;">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreate?.name }} </small>
                </template>
            </Column>
            <Column field="dt_update" :sortable="true" sortField="epc.dt_update" header="Atualizado" bodyStyle="min-width: 15rem;">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userUpdate?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany } from '../../services/store';

export default {
    data() {
        return {
            record: {}
        };
    },
    created() {
        this.service = new SesmtService('/epc');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar EPC' : 'Adicionar EPC';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },

    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>
